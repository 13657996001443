import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import Layout from '../components/layout'
import SEO from '../components/seo'

export const InfoQuery = graphql`
  query Info {
    prismicInfo {
      data {
        bio_title {
          text
        }
        bio_description {
          html
        }
        contact_title {
          text
        }
        address {
          text
        }
        adress_link {
          url
        }
        email {
          text
        }
        instagram {
          text
        }
        instagram_link {
          url
        }
        phone {
          text
        }
        client_title {
          text
        }
        clients {
          client_name {
            text
          }
        }
        show_title {
          text
        }
        show_item {
          show_info {
            html
          }
        }
      }
    }
  }
`

const InfosPage = ({ data }) => {
  const { clients } = data.prismicInfo.data
  const shows = data.prismicInfo.data.show_item

  const infoOneAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 300,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const infoTwoAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Layout>
      <SEO
        title="Info"
        keywords={[
          `Artist`,
          `London`,
          `graffiti`,
          `collage`,
          `University of Brighton`,
          `design`,
          `sculpture`
        ]}
      />
      <WrapInfos>
        <WrapInfoText style={infoOneAnim}>
          <h2>{data.prismicInfo.data.bio_title.text}</h2>
          <div dangerouslySetInnerHTML={{ __html: data.prismicInfo.data.bio_description.html }} />
        </WrapInfoText>

        <WrapContact style={infoTwoAnim}>
          <h2>{data.prismicInfo.data.contact_title.text}</h2>
          <p>
            <a
              href={data.prismicInfo.data.adress_link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.prismicInfo.data.address.text}
            </a>
          </p>
          <p>
            <a href={`mailto:${data.prismicInfo.data.email.text}?subject=Hello%20Jamie`}>
              {data.prismicInfo.data.email.text}
            </a>
          </p>
          <p>
            <a href={`tel:${data.prismicInfo.data.phone.text}`}>
              {data.prismicInfo.data.phone.text}
            </a>
          </p>
          <p>
            <a
              href={data.prismicInfo.data.instagram_link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.prismicInfo.data.instagram.text}
            </a>
          </p>
        </WrapContact>

        <WrapClientsList>
          <h2>{data.prismicInfo.data.client_title.text}</h2>
          {clients.map((clientItem, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: clientItem.client_name.text }} />
          ))}
        </WrapClientsList>

        <WrapShowList>
          <h2>{data.prismicInfo.data.show_title.text}</h2>
          {shows.map((showItem, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: showItem.show_info.html }} />
          ))}
        </WrapShowList>

        <div
          className="site-by"
          href="http://www.adrien-m.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          * Design & Dev by
          <a href="http://www.adrien-m.com/" target="_blank" rel="noopener noreferrer">
            adrien-m
          </a>
        </div>
      </WrapInfos>
    </Layout>
  )
}

const WrapInfos = styled.section`
  max-width: calc(100% - 50px);
  margin: 25vh auto 100px auto;
  display: flex;
  flex-wrap: wrap;
  font-weight: 300 !important;

  @media screen and (min-width: 769px) {
    width: 75%;
    max-height: 75%;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
    max-height: 50%;
  }

  h2 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 300;
    font-size: 14px;
  }

  p {
    font-size: 22px;
    line-height: 1.66;
    display: block;
    margin-bottom: 1rem;

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  .site-by {
    display: inline;
    margin: 20px 0;
    padding: 25px 0;
    background-color: #ffffff;
    z-index: 50;
    color: #c2c2c2;
    font-size: 11px;

    @media screen and (min-width: 767px) {
      width: 95%;
      margin: 20px auto;
    }

    @media screen and (min-width: 1024px) {
      padding: 25px 20px;
      width: 80%;
    }

    a {
      color: #c2c2c2;
      margin-left: 5px;
    }
  }
`

const WrapInfoText = styled(animated.section)`
  position: relative;
  width: 100%;
  margin: 20px auto;
  padding: 50px 0;
  background-color: #ffffff;
  z-index: 50;

  @media screen and (min-width: 767px) {
    width: 95%;
  }

  @media screen and (min-width: 1024px) {
    padding: 50px 20px;
    width: 80%;
  }

  a {
    color: #0c2340;
    transition: background-color 0.5s cubic-bezier(0.05, 0.16, 0.05, 0.95);

    &:hover {
      /* background-color: #ededed; */
      color: #c0c0c0;
      border-bottom: 1px solid black;
    }
  }
`

const WrapContact = styled(animated.section)`
  position: relative;
  width: 100%;
  margin: 20px auto;
  padding: 50px 0;
  background-color: #ffffff;
  z-index: 50;

  @media screen and (min-width: 767px) {
    width: 95%;
  }

  @media screen and (min-width: 1024px) {
    padding: 50px 20px;
    width: 80%;
  }

  p {
    font-size: 22px;
    margin-bottom: 1rem;

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  a {
    font-size: 22px;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    width: auto;
    /* margin-left: -20px; */
    cursor: pointer;
    color: black;
    line-height: 1.5;
    border-bottom: 1px solid transparent;
    /* padding: 10px 20px 8px 20px; */
    /* border-radius: 4px; */
    transition: color 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95),
      background 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95);

    &:hover {
      padding: 0;
      /* color: #c0c0c0; */
      border-bottom: 1px solid black;
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
`

const WrapClientsList = styled.section`
  position: relative;
  width: 100%;
  margin: 20px auto;
  padding: 50px 0;
  background-color: #ffffff;
  z-index: 100;

  @media screen and (min-width: 767px) {
    width: 95%;
  }

  @media screen and (min-width: 1024px) {
    padding: 50px 20px;
    width: 80%;
  }

  p {
    font-size: 22px;
    margin-bottom: 1rem;

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  a {
    font-size: 22px;
    padding: 5px 10px;
    cursor: pointer;
    color: black;
    transition: color 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95),
      background 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95);

    &:hover {
      color: #ededed;
      background: #2f2f2f;
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
`

const WrapShowList = styled.section`
  position: relative;
  width: 100%;
  margin: 20px auto;
  padding: 50px 0;
  background-color: #ffffff;
  z-index: 100;

  @media screen and (min-width: 767px) {
    width: 95%;
  }

  @media screen and (min-width: 1024px) {
    padding: 50px 20px;
    width: 80%;
  }

  p {
    font-size: 22px;

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  a {
    display: inline-block;
    font-size: 22px;
    border-bottom: 1px solid transparent;
    /* padding: 5px 10px; */
    cursor: pointer;
    color: black;
    transition: color 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95),
      background 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95);

    &:hover {
      /* color: #ededed; */
      /* background: #2f2f2f; */
      border-bottom: 1px solid black;
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
`

export default InfosPage
